/* global fetch */ // nextjs polyfills
// Helper Lib
// cSpell:ignore ngql, carg, Rmllb, GREZW, Zpbml, CFDK, DDTHH, Reallylongname, Withmany, Wordsthat, Cango
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import util from 'util'
import slugify from 'slugify'
import packageFile from '../../package.json'
import { cloneDeep as deepCopy, cloneDeep, invert, isEmpty } from 'lodash'
import { timeZones, ignoreDST, states, phoneAreaCodes } from '../dictionaries/dictionaries'
import FormData from 'form-data'
const { version: appVersion } = packageFile
export { deepCopy, cloneDeep, invert } // { cloneDeep as deepCopy, cloneDeep, invert } from 'lodash'
export const jsonwebtoken = require('jsonwebtoken')
const hasOwnProperty = Object.prototype.hasOwnProperty
export const moment = require('moment')
export const _ = require('lodash')
export const immutableObjUpdate = require('immutability-helper')
export const safeAwait = require('safe-await')
const mongoose = require('mongoose')
const { customAlphabet } = require('nanoid')
const { ObjectId } = mongoose.Types
ObjectId.prototype.valueOf = function () {
  return this.toString()
}

export const isNumber = (value) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
  if (value === 0) return true
  if (!value) return false
  if (value === ' ') return false
  return !Number.isNaN(Number(value))
}

export const replaceStringArrayElement = (searchArray, find, replace) => {
  // consoleDev(thisFile, 'replaceStringArrayElement searchArray:', searchArray, ' \nfind:', find, ' \nreplace:', replace)
  if (searchArray.indexOf(find) > -1) searchArray[searchArray.indexOf(find)] = replace
}
export const columnLetters = [
  '0',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN'
]
export const clientWindow = typeof window !== 'undefined' ? window : undefined
const JsonDiffPatch = require('jsondiffpatch')
// instance of JsonDiffPatch
export const jsonDiffPatch = JsonDiffPatch.create({
  objectHash: function (obj, index) {
    if (obj !== undefined) {
      return (obj._id && obj._id.toString()) || obj.id || obj.key || '$$index:' + index
    }
    return '$$index:' + index
  },
  arrays: {
    detectMove: true
  }
})

const thisFile = 'src/helpers/helper ' // eslint-disable-line no-unused-vars

const FAKE_NODE_ENV_PRODUCTION = false

/**
 *  Is used to get the slug from displayId.  Removes STA- if exists and returns up to the first '-' (hyphen).
 *
 * @param {*} displayId
 * @returns
 */
export const getSlugFromDisplayId = (displayId) => {
  if (displayId?.substr(0, 4) === 'STA-') displayId = displayId?.substr(4)
  const firstHyphen = displayId?.indexOf('-')
  if (~firstHyphen) {
    return displayId?.substr(0, firstHyphen)
  } else {
    throw new Error(consoleError(thisFile, 'getSlugFromDisplayId:', displayId, 'failed with firstHyphen:', firstHyphen))
  }
}

/**
 *
 *  Makes stringified messages look nice by removing escape markup
 *
 * @param {*} args
 * @param {*} data
 * @returns
 */
export const stringifyReturnMessage = (args, data) => {
  return (
    jsonStr(args).replace(/"/g, '').replace(/\n/g, '') +
    ' data: ' +
    jsonStr(data).replace(/"/g, '').replace(/\n/g, '').replace(/\s\s+/g, '')
  ) // last one multi spaces
}

/**
 *  Mongoose doesn't like to be called with await and 6.0 enforced.  This makes it valid syntax
 *  to only get called once.
 *
 * @param {*} model
 * @param {*} { search, update, options }
 * @returns
 */
export const findOneAndUpdatePromise = (model, { search, update, options }) => {
  // delele _id from the update if it contains becuase it is Immutable field
  // eslint-disable-next-line no-prototype-builtins
  update?.$set?.hasOwnProperty('_id') && delete update.$set._id
  return new Promise((resolve, reject) => {
    model.findOneAndUpdate(search, update, options, (err, doc) => {
      if (err) {
        consoleWarn(`Could not update ${model}. Details: ` + JSON.stringify(err))
        reject(new Error(`Could not update ${model}`))
      }
      resolve(doc)
    })
  })
}

/**
 * @typedef {Object} ConvertedQuestions
 * @property {Array<import('@src/dictionaries/commonInterfaces').Question>} convertedQuestions The given questions with select answerOptions converted.
 * @property {Record<string, any>} convertOperations An array of operations that can be given to MongoDB
 * to bulk convert the answerOptions field.
 */

/**
 * Turn any string delimited answers of a question into an array. If the answers to a
 * question are already an array, this is a no-op. This is a pure function, so it will
 * return operations for the database to do but will not operate on the database itself.
 * @param {Array<import('@src/dictionaries/commonInterfaces').Question>} questions
 * @returns {ConvertedQuestions} A ConvertedQuestions object with the changes.
 */
export function convertAnswerOptions(questions) {
  const answerType = 'Select '
  const delimiter = ';'

  return {
    // Map the entire questions array to a new array with any answerOptions converted.
    convertedQuestions: questions.map((question) => {
      // Any questions we aren't going to touch should at least be empty arrays, not null.
      if (!question.answerOptions) question.answerOptions = []

      // We really only want to mess with certain answer types.
      return question.answerType?.includes(answerType)
        ? {
            // Get all the current keys of the question.
            ...question,

            // For answerOptions, if it's a string, split it into an array.
            answerOptions:
              typeof question.answerOptions === 'string'
                ? question.answerOptions.split(delimiter)
                : // If not, return it as-is, or if null an empty array.
                  question.answerOptions || []
          }
        : // The answer type didn't match so just return the question as-is.
          question
    }),

    // Any questions that require answerOptions to be converted, create an updateOne for it.
    convertOperations: questions
      .filter((question) => typeof question.answerOptions === 'string')
      .map((question) => {
        return {
          [`questions.${question.questionSlug}.answerOptions`]: question.answerOptions.split(delimiter)
        }
      })
      .reduce((acc, curr) => {
        return { ...acc, ...curr }
      }, {})
  }
}

/**
 * Taken from https://stackoverflow.com/questions/4994201/is-object-empty
 * If you only need to handle ECMAScript5 browsers, you can use Object.getOwnPropertyNames instead of the hasOwnProperty loop:
 *  if (Object.getOwnPropertyNames(obj).length) return false
 * @param obj
 * @returns {boolean}
 * @see user.js 2 usages
 */
export function isEmptyObject(obj) {
  // null and undefined are "empty"
  if (obj == null) return true

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false
  if (obj.length === 0) return true

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false
  }

  return true
}

/**
 * takes in object path in dot notation and returns the value
 * @param {Object} object { a: { b: { c: { d: 'testVal' } } }
 * @param {String} pathString 'a.b.c.d'
 * @returns value at index, 'testVal'
 */
export const objectValByPath = (object, pathString) => {
  pathString = pathString.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  pathString = pathString.replace(/^\./, '') // strip a leading dot
  const arr = pathString.split('.')
  for (let i = 0, n = arr.length; i < n; ++i) {
    const key = arr[i]
    if (!key || !object) return
    if (key in object) {
      object = object[key]
    } else {
      return
    }
  }
  return object
}

/**
 * A more modern JavaScript and TypeScript implementation of a simple object to flat property map converter. It's using Object.entries to do a proper for of loop only on owned properties.
 * https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array/59787588
 *
 * @export
 * @param {*} object
 * @param {string} [keySeparator='.']
 * @returns
 */
export function toFlatPropertyMap(object, keySeparator = '.') {
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap)
      } else {
        propertyMap[property] = value
      }
    }
    return propertyMap
  }
  return flattenRecursive(object)
}
/**
 * this funstion take a nested object flatten it and preserving both  keys and values
 */
export function flattenedObject(nestedObject) {
  return _.transform(
    nestedObject,
    (result, value, key) => {
      if (_.isPlainObject(value)) {
        // Recursively flatten nested objects
        const nested = _.transform(value, (nestedResult, nestedValue, nestedKey) => {
          nestedResult[`${key}.${nestedKey}`] = nestedValue
        })
        _.assign(result, nested)
      } else {
        result[key] = value
      }
    },
    {}
  )
}

/**
 * takes a path string and inserts it into the supplied Object
 * @param {String} objectPathStringsArray
 * @param {Object} obj
 * @returns {Object}
 */
export const objectPathsToObject = (objectPathStringsArray, obj = {}) => {
  if (objectPathStringsArray.length === 0) return obj
  for (const projectKey of objectPathStringsArray) {
    // Cache the path length and current spot in the object
    const path = projectKey.split('.')
    const length = path.length
    let current = obj
    // Loop through the path
    path.forEach((key, index) => {
      // If this is the last item in the loop, assign the value
      if (index === length - 1) {
        current[key] = key
      } else {
        // Otherwise, update the current place in the object
        // If the key doesn't exist, create it
        if (!current[key]) {
          current[key] = {}
        }
        // Update the current place in the objet
        current = current[key]
      }
    })
  }
  return obj
}

/**
 * converts an object to an array of object paths
 * @param {Object} object
 * @param {String} currentLocation
 * @param {Array} pathArray
 * @returns
 */
export const objectToObjectPathArrayOLD = (object, currentLocation = '', pathArray = []) => {
  for (const [key, value] of Object.entries(object)) {
    // consoleDev(thisFile, 'objectToObjectPathArray object:', object, 'key:', key, 'value:', value)
    if (typeof value === 'string') {
      pathArray.push(currentLocation + key)
    } else if (Array.isArray(value)) {
      pathArray.push(currentLocation + key)
    } else if (typeof value === 'object') {
      objectToObjectPathArray(value, currentLocation + key + '.', pathArray)
    }
  }
  return pathArray
}

/**
 * converts an object to an array of object paths
 * @param {Object} object
 * @param {String} currentLocation
 * @param {Array} pathArray
 * @returns
 */
export const objectToObjectPathArray = (object, currentLocation = String()) => {
  // consoleDev(thisFile, 'objectToObjectPathArray: object:', object, 'currentLocation', currentLocation)
  if (currentLocation && currentLocation.slice(-1) !== '.') currentLocation += '.'
  if (currentLocation === '.') currentLocation = String()
  return Object.keys(toFlatPropertyMap(object)).map((key) => currentLocation + key)
}

/**
 * Boolean for detecting Invalid Date
 *
 * @export
 * @param {*} d
 * @returns
 */
export function isValidDate(date) {
  return date instanceof Date && !isNaN(date)
}

/**
 * takes a date as a string and formats it to local settings
 * @param {string} dateToBeLocalized
 * @returns {string} localizedDateString
 */
export function localizeDateFormat(dateToBeLocalized, local = 'en-US', options = {}) {
  return new Date(dateToBeLocalized).toLocaleString(local, options)
}

/**
 * given a date range this function returns the difference in minutes and the offset from now
 * e.g. {start: Date, end: Date} where the start is 24 hours prior to now and end is now would return {start: "1440minutes", end: "0minutes"}
 * @param {Object} dateRange
 * @returns {Object} dynamic date range
 */
export const getDynamicDateRange = (dateRange) => {
  const returningRange = { start: dateRange.start, end: dateRange.end }
  const now = moment()
  const start = moment(dateRange.start)
  const end = moment(dateRange.end)
  const durationEndNow = moment.duration(now.diff(end)).as('minutes')
  const durationStartEnd = moment.duration(start.diff(end)).as('minutes')
  if (durationEndNow < 5) {
    // if the "end" date is within 5 minutes of now, assume now for the query
    returningRange.end = '0minutes'
  } else {
    // user may intend to omit the most recent entries
    returningRange.end = Math.abs(durationEndNow) + 'minutes'
  }
  returningRange.start = Math.abs(durationStartEnd) + 'minutes' // difference between start and end
  return JSON.stringify(returningRange)
}

/**
 * given a date range with dynamic strings it returns a relevant date range
 * e.g. {start: "1440minutes", end: "0minutes"} will return a date range of the last 24 hours
 * @param {Object} dateRange
 * @returns {Object} dateRange
 */
export const getNewDateRangesForSearch = (dateRange) => {
  if (~dateRange.end.indexOf('minutes')) {
    dateRange.end = dateRange.end.replace('minutes', '')
    dateRange.end = moment().subtract(dateRange.end, 'minutes')
  }
  if (~dateRange.start.indexOf('minutes')) {
    dateRange.start = dateRange.start.replace('minutes', '')
    dateRange.start = moment(dateRange.end).subtract(dateRange.start, 'minutes')
  }
  return dateRange
}

/**
 * Get parts of a date for de structured string building
 *
 * @param {*} date
 * @returns
 */
export const getDateParts = (dateFrom) => {
  if (!dateFrom) return
  return {
    year: dateFrom.getFullYear(),
    day: dateFrom.getDate(),
    month: dateFrom.getMonth() + 1 // 0 based index
  }
}

/**
 * Get parts of a date in UTC for de structured string building
 *
 * @param {*} date
 * @returns
 */
export const getDatePartsUTC = (dateFrom) => {
  if (!dateFrom) return
  const dateParts = {
    year: dateFrom.getUTCFullYear(),
    year2: dateFrom.getUTCFullYear().toString().substr(2, 2),
    day: dateFrom.getUTCDate(),
    day2: String(dateFrom.getUTCDate()).padStart(2, '0'),
    month: dateFrom.getUTCMonth() + 1, // 0 based index
    month2: String(dateFrom.getUTCMonth() + 1).padStart(2, '0') // 0 based index
  }
  return dateParts
}

/**
 * Returns date string according to RI Standards.
 *
 * @param {*} dateFrom
 * @param {*} separatorCharacter
 * @returns
 */
export const getRIDateString = (dateFrom, separatorCharacter = '-') => {
  const { year, day, month } = getDateParts(dateFrom)
  return (
    year +
    separatorCharacter +
    (Number(month) < 10 ? '0' : String()) +
    month +
    separatorCharacter +
    (Number(day) < 10 ? '0' : String()) +
    day
  )
}

/**
 * takes a json obj builds a string literal
 * @param filtersObjectAndOrArray
 * @returns {string} literal
 * @see ../app/client/pages/SalesOppBoiler.js 1 usage
 * @see ../app/controllers/graphql/cart.js 2 usages
 * @see ../app/controllers/graphql/user.js 2 usages
 * @see ../app/lib/cartHelper.js 8 usages
 * @see ../app/lib/webhookHelper.js 8 usages
 * @see ../app/client/pages/SalesOppBoiler.js 1 usage
 */
export function getFiltersString(filtersObjectAndOrArray) {
  if (!Array.isArray(filtersObjectAndOrArray)) filtersObjectAndOrArray = [filtersObjectAndOrArray]
  const filtersFormatted = filtersObjectAndOrArray.map(function (filter) {
    return '{field: ' + filter.field + ', operation: ' + filter.operation + ', value: "' + filter.value + '"}'
  })

  return filtersFormatted.join(',')
}

/**
 * Makes very common filter
 * @param id
 * @returns {string}
 */
export const filterById = (id) => {
  const filter = getFiltersString({
    field: 'id',
    operation: 'eq',
    value: id
  })

  return filter
}

/**
 * Get cookies from serverside req headers
 * @param key
 * @param req
 * @returns {*}
 */
export function getCookieFromServer(key, req) {
  if (!req) Error(consoleError(thisFile, 'getCookieFromServer Error empty req'))
  if (!req.headers.cookie) {
    return undefined
  }
  const rawCookie = req.headers.cookie.split(';').find((c) => c.trim().startsWith(key + '='))
  if (!rawCookie) {
    return undefined
  }
  return rawCookie.split('=')[1]
}

/**
 * This is a catchall for api calls that should be able to find errors anywhere in the error object
 * and it isn't finished.  There are some errors that still don't get reported right, Added the
 * stack, query, and variables objects to return, to diagnose.
 * @param error
 * @param errorStack
 * @param query
 * @param variables
 * @returns {object} errors
 */
export function handleGqlMakeQueryError(error, query, variables) {
  // consoleDev('functionHelper.handleGqlMakeQueryError')
  const response = error.response
  const failsafe = { errors: [{ message: JSON.stringify(error) }, query, variables] }
  if (!response) {
    // Probably thrown ourselves from sfGraphQL client, if there is a message, go with it.
    if (error.message) return { errors: [{ message: error.message }] }
    // Failsafe, return everything
    return failsafe
  }
  if (!response.errors) {
    // Single error needs to be converted to array.  A response needs to be either valid data or contain
    // errors array.
    if (response.error) {
      const errorsArr = [response.error]
      delete response.error
      response.errors = errorsArr
    } else {
      return failsafe
    }
  }
  const returnObj = Object.assign({}, response, error.request)
  // consoleDev('functionHelper.handleGqlMakeQueryError returnObj = ' + JSON.stringify(returnObj))
  return returnObj
}

/**
 * looks for null for undefined text and makes them an undefined type
 * @param arg
 * @returns {array}
 */
export function removeResolverArgsStringifiedNullAndUndefined(arg) {
  for (const key in arg) {
    if (arg[key] === 'null' || arg[key] === 'undefined') {
      arg[key] = undefined
    }
  }
  return arg
}
/**
 * Checks string array for dupe element and returns the first one it finds
 *
 * @export
 * @param {*} stringArrayToCheck
 * @returns
 */
export function dupeInStringArray(stringArrayToCheck) {
  // consoleDev(thisFile, 'dupeInStringArray: stringArrayToCheck', stringArrayToCheck)
  for (let i = 0; i < stringArrayToCheck.length; i++) {
    for (let j = i + 1; j < stringArrayToCheck.length; j++) {
      if (typeof stringArrayToCheck[i] !== 'string')
        throw new Error(thisFile, 'dupInStringArray: Non string element found.')
      if (stringArrayToCheck[i] === stringArrayToCheck[j]) {
        // got the duplicate element
        return stringArrayToCheck[i]
      }
    }
  }

  // Read more: https://javarevisited.blogspot.com/2015/06/3-ways-to-find-duplicate-elements-in-array-java.html#ixzz6C9Xxx4bX
}

/**
 * Makes sure object is not a reference to another
 * @param {*} objRef
 */
export function jsonCopy(objRef) {
  return JSON.parse(JSON.stringify(objRef))
}

export function copyToClipboard(document, str) {
  if (!(document && str)) return
  const el = document.createElement('textarea') // Create a <textarea> element
  el.value = str // Set its value to the string that you want copied
  el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
  el.style.position = 'absolute'
  el.style.left = '-9999px' // Move outside the screen to make it invisible
  document.body.appendChild(el) // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false // Mark as false to know no selection existed before
  el.select() // Select the <textarea> content
  document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el) // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges() // Unselect everything on the HTML document
    document.getSelection().addRange(selected) // Restore the original selection
  }
}

export function downloadFile(document, href, fileName) {
  const element = document.createElement('a')
  element.style.display = 'none'
  element.href = href
  element.title = fileName
  element.type = 'application/csv'
  element.target = '_self'
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

/**
 * from: https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
 * This will ALWAYS return a float!
 * @param {*} n
 * @param {*} digits
 */
export function roundTo(n, digits) {
  let negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  const multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = parseFloat((Math.round(n) / multiplicator).toFixed(digits))
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return parseFloat(n)
}

/**
 * checks to see if the user has access to the location
 * @param {String} location - the string location/permission to see if the user has access to
 * @param {UserFromToken} user - the user object from the token
 * @returns {Boolean}
 */
export function hasAccess(location, user) {
  if (!location) return true
  return user?.permissions?.includes(location) || false
}
/**
 * used to determine if user has the role of roleNameToCheck
 * @param {Object} user
 * @param {String} roleNameToCheck
 * @returns Boolean
 */
export function hasUserRole(user, roleNameToCheck) {
  if (!user?.roles?.length) return false
  for (const role of user.roles) {
    if (role.name === roleNameToCheck) return true
  }
  return false
}

/**
 * The roundTo function guarantees to return a float.  This simply calls that one in turn and returns the results as a string.
 * For convenience, call this instead of String(roundTo(n,x)).toFixed(x)
 * @param {*} n
 * @param {*} digits
 */
export function roundToFixed(n, digits) {
  return roundTo(n, digits).toFixed(digits)
}

/**
 *
 *
 *  a11yProps is material-ui helper function found in their docs.
 *  It's just a function for making a dynamic object and using them as props, to reduce code size.
 * https://material-ui.com/components/tabs/
 *
 * https://stackoverflow.com/questions/57305891/how-to-show-contents-inside-a-tab
 * @param {*} index
 * @returns
 */
export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

/**
 * Recursive function to return all values of provided key with path, level, and messages
 * @param {*} searchObject
 * @param {*} searchKey
 * @param {*} levels
 * @param {*} pathString
 * @param {*} currentLevel
 */
export const findValuesByKey = (searchObject, searchKey, levels = 3, pathString = String(), currentLevel = 0) => {
  // const defaultValueObject = {
  //   level: -1,
  //   value: undefined,
  //   path: undefined
  // }
  const returnValuesObject = {
    values: [],
    msg: String()
  }
  if (currentLevel > levels) {
    returnValuesObject.msg = 'Max level reached.'
    return returnValuesObject
  }
  if (typeof searchObject === 'object' && searchObject !== null) {
    for (const [key, value] of Object.entries(searchObject)) {
      if (key === searchKey) {
        returnValuesObject.values.push({
          level: currentLevel,
          value,
          path: pathString
        })
      } else {
        const newPath = pathString ? pathString.concat('.' + key) : key
        const resultValuesObject = findValuesByKey(searchObject[key], searchKey, levels, newPath, currentLevel + 1)
        if (resultValuesObject.values.length || resultValuesObject.msg) {
          returnValuesObject.values = returnValuesObject.values.concat(resultValuesObject.values)
          returnValuesObject.msg += (returnValuesObject.msg ? ', ' : String()) + resultValuesObject.msg
        }
      }
    }
  }
  return returnValuesObject
}

/**
 *
 *
 * @param {*} obj
 * @returns
 */
export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj[Symbol.iterator] === 'function'
}

/**
 *
 *
 * @param {*} fromString
 * @param {*} DEBUG
 * @returns
 */
export const getFirstNumberFromString = (fromString, DEBUG = false) => {
  if (DEBUG) consoleDev(thisFile, 'getFirstNumberFromString fromString:', fromString)
  if (!fromString) return
  if (!isIterable(fromString)) return

  let index = 0
  for (const character of fromString) {
    if (DEBUG) consoleDev(thisFile, 'getFirstNumberFromString character:', character)
    if (isNumber(character)) {
      if (DEBUG) consoleDev(thisFile, 'isNumber returning:', { character, index })
      return { character, index }
    } else if (DEBUG) consoleDev(thisFile, '! isNumber')
    index++
  }
}
/**
 * Simple Integer to letter converter
 *
 * @param {*} number
 * @param {boolean} [capital=true]
 * @returns
 */
export const getLetterFromNumber = (number, capital = true) => {
  const letter = (number + 9).toString(36).toUpperCase()
  return capital ? letter.toUpperCase() : letter
}

/**
 * formats the phone number for display
 * NOTE: this function is for static number strings not for input fields that have variable length
 * @param {String} phone
 * @returns formatted phone number as xxx-xxx-xxxx
 */
export const formatPhoneNumber = (phone) => {
  if (!phone) return
  if (typeof phone !== 'string') return
  let phoneString = phone
  phoneString = phoneString.replace('+1', '') // remove leading +1
  let cleaned = phoneString.replace(/\D/g, '') // remove anything that is not a number
  if (cleaned.length > 10) {
    // return only the last 10 digits so that 1 (xxx) xxx-xxxx only returns xxx-xxx-xxxx
    cleaned = cleaned.substring(cleaned.length - 10)
  }
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/) // match the first 3 digits, the middle 3 digits, and the last 4 digits
  let formattedPhoneString = ''
  if (match) {
    formattedPhoneString = match[1] + '-' + match[2] + '-' + match[3] // format to xxx-xxx-xxxx
  } else {
    formattedPhoneString = phone // if it doesn't match, just return the original phone input
  }
  return formattedPhoneString
}

/**
 * Worldpay uses an exponent value instead of decimal point.  This function will convert a
 * floating point to an integer with said exponent.  e.g.  10.99 x2  becomes 1099
 * @param {*} incomingFloat
 * @param {*} exponent
 */
export const float2ExponentInt = (incomingFloat, exponent = 2) => {
  return parseInt(roundToFixed(incomingFloat, exponent).replace('.', ''))
}

/**
 * @description splits a string on its spaces and capitalizes each word, ignoring the case of the rest of the chars, and rejoins them
 * @param {String} text
 */
export const capitalizeString = (text) => {
  const wordsArray = text.split(' ')
  const capWordsArray = wordsArray.map((str) => str.charAt(0).toUpperCase() + str.substring(1))
  const returnString = capWordsArray.join(' ')
  return returnString
}

/**
 * Capitalizes first character of string and returns said string.
 *
 * @param {*} charString
 * @returns
 */
export const capitalizeFirst = (charString) => {
  if (typeof charString !== 'string') return charString
  return charString.charAt(0).toUpperCase() + charString.slice(1)
}

/**
 * Get quoted 'value:' part of query string.  FAST IS KEY!
 * @param {*} query
 */
export const getKeyValueFromQuery = (query, key) => {
  const keyIndex = query.indexOf(key + ':')
  if (keyIndex === -1) return
  const firstQuote = query.indexOf('"', keyIndex + 1)
  if (firstQuote === -1) return
  const secondQuote = query.indexOf('"', firstQuote + 1)
  if (secondQuote === -1) return
  return query.substr(firstQuote + 1, secondQuote - (firstQuote + 1))
}

/**
 * Get returnList object part of query string.  FAST IS KEY!
 * @param {*} query
 */
export const getReturnListFromQuery = (query) => {
  const lastCloseBracket = query.lastIndexOf('}')
  if (lastCloseBracket === -1) return
  const next2lastCloseBracket = query.lastIndexOf('}', lastCloseBracket - 1)
  if (next2lastCloseBracket === -1) return
  const lastCloseParen = query.lastIndexOf(')')
  if (lastCloseParen === -1) return
  const bracketAfterLastCloseParen = query.indexOf('{', lastCloseParen + 1)
  if (bracketAfterLastCloseParen === -1) return

  return query.substr(bracketAfterLastCloseParen + 1, next2lastCloseBracket - (bracketAfterLastCloseParen + 1))
}

/**
 * This function aims to be the most efficient way to look for certain queries
 * to facilitate using Redis cache.  FAST IS KEY!
 * @param {*} query
 */
export const getQueryProps = (query) => {
  query = query.trim()
  const firstChar = query.substr(0, 1).toLowerCase()
  const firstBracket = query.indexOf('{', firstChar + 1)
  if (firstBracket === -1) return
  if (firstChar === 'm') {
    const secondBracket = query.indexOf('{', firstBracket + 1)
    if (secondBracket === -1) return
    const firstParen = query.indexOf('(', secondBracket + 1)
    if (firstParen === -1) return
    const closeParen = query.indexOf(')', firstParen)
    const parameters = query.substr(firstParen + 1, closeParen - (firstParen + 1))
    const name = query.substr(secondBracket + 1, firstParen - (secondBracket + 1)).trim()
    return {
      type: firstChar,
      name,
      value: getKeyValueFromQuery(query, 'value'),
      parameters,
      returnList: getReturnListFromQuery(query)
    }
  } else {
    const firstParen = query.indexOf('(', firstBracket + 1)
    if (firstParen === -1) return
    const closeParen = query.indexOf(')', firstParen)
    const parameters = query.substr(firstParen + 1, closeParen - (firstParen + 1))
    const name = query.substr(firstBracket + 1, firstParen - (firstBracket + 1)).trim()
    return {
      type: firstChar,
      name,
      value: getKeyValueFromQuery(query, 'value'),
      parameters,
      returnList: getReturnListFromQuery(query)
    }
  }
}

export const returnError = async (returnObject) => {
  if (returnObject && (returnObject.errors || returnObject.error)) return true
}

/**
 * Nice ways to stringify output
 * @param {*} obj
 */
export const jsonStr = (obj, limit = undefined) => {
  let returnString = ''

  try {
    returnString = JSON.stringify(obj, null, 4)
  } catch (error) {
    if (error.message.substr(0, 37) === 'Converting circular structure to JSON') {
      returnString = 'circular - inspect:\n'
      returnString += util.inspect(obj)
    } else {
      consoleError('functionHelper.jsonStr error: [', error.message, ']')
      consoleError('functionHelper.jsonStr obj: ', obj)
      returnString = error.message
    }
  }

  if (limit) return returnString.substr(0, limit)
  return returnString
}

/**
 * Simple delay
 * @param {*} ms
 */
export const delay = (ms) => {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms)
  })
}

/**
 * Adds nice countdown console for delay
 * @param {*} msg
 * @param {*} ms
 * @param {*} callback
 * @param {*} payload Either array of functions to call and return with promise.all. First element will be function to call with array of results from the rest.
 * Or Simple return value (if not an array)
 */
export const delayCountdown = async (msg, ms, callback, payload) => {
  if (isNaN(ms)) {
    if (!isNaN(Number(msg))) {
      // if directly replacing delay()
      ms = Number(msg)
      msg = 'Defaulted to delay(ms)'
    } else {
      throw new Error('functionHelper.delayCountdown <' + ms + '> is NaN')
    }
  }
  let step = 1000
  if (ms <= 1000) step = 100
  let counter = parseInt(Number(ms) / step)
  consoleWarn(msg, ' DELAY: ', counter, ' ', step === 1000 ? 'seconds' : 'tenths of a second')
  const message = msg + ' DELAY: ' + counter
  callback && callback(message)
  while (counter--) {
    await delay(step)
    consoleWarn(msg, ' DELAY: ', counter, ' ', step === 1000 ? 'seconds' : 'tenths of a second')
    callback && callback(message)
  }

  // Payload now is array of functions to call and return with promise.all
  // First element will be function to call with array of results from the rest

  // Old was to simply return value
  if (Array.isArray(payload)) {
    const returnFunction = payload.shift()
    const payloadResultArray = await Promise.all(payload)
    return returnFunction(payloadResultArray)
  } else {
    return payload
  }
}

/**
 *
 * Provides a wrapper for authentication messages so we can control when / where reveal information
 * and to what depth since this is a security risk.
 *
 * @param {*} fileName
 * @param {*} functionName
 * @param {*} message
 * @returns
 */
export const authError = (fileName, functionName, message) => {
  if (variantToBool(process.env.RI_DEBUG) || variantToBool(process.env.AUTH_ERROR_DEBUG)) {
    return fileName + ' ' + functionName + ' ' + message
  } else {
    return 'You are not authenticated!'
  }
}
/**
 *
 *
 * @param {*} ms
 * @param {*} promise
 * @returns
 */
export const promiseTimeout = (ms, promise) => {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      reject(new Error('Timed out in ' + ms + 'ms.'))
    }, ms)
  })

  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout])
}

/**
 * Catch all function for any variable that must covert to boolean.
 * @param {*} envVariable
 * @returns {Boolean} primitive
 */
export const variantToBool = (envVariable) => {
  if (!envVariable) return false // Not set
  if (typeof envVariable === 'boolean') return envVariable
  // Checked for unset and boolean primitives.  The rest should be strings.
  if (!(typeof envVariable === 'string')) envVariable = jsonStr(envVariable)
  // All strings default to true unless one of the following.
  switch (envVariable.toLowerCase()) {
    case 'squat':
    case 'bubkis':
    case 'bupkis':
    case 'off':
    case 'offline':
    case 'false':
    case 'f':
    case 'no':
    case 'n':
    case 'not':
    case 'nill':
    case 'zero':
    case 'nada':
    case 'negative':
    case 'zilch':
    case 'null':
    case 'empty':
    case 'nowayhosay':
    case 'elvishasleftthebuilding':
    case 'leftthetracks':
    case 'undefined':
    case '0':
    case 0:
    case null:
    case undefined:
    case 'nan': // Result from Number() when Not A Number
    case String(): // Empty
    case 'n/a': // Human reference to "Not Applicable"
      return false
    default:
      return true
  }
}

const consoleReset = '\x1b[0m'
const consoleRed = '\x1b[31m'
const consoleGreen = '\x1b[32m'
const consoleYellow = '\x1b[33m'
const consoleBlue = '\x1b[34m'
const consoleMagenta = '\x1b[35m'
const consoleCyan = '\x1b[36m'

// Good alternate example
// console.log('%c Oh my heavens! ', 'background: #222; color: #bada55')

const consoleFireTest =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test' ||
  process.env.ENV === 'development' ||
  process.env.ENV === 'test' ||
  variantToBool(process.env.RI_DEBUG)
/**
 * Wraps normal console.log with if development and MAKES VERY NOTICEABLE.
 */
export function consoleATTN() {
  const argsArray = Array.prototype.slice.call(arguments)
  if (consoleFireTest) {
    console.info(
      '\x1b[35m\n\n\n###############################################################################################################'
    )
    console.info(
      '###########################################  ATTENTION  #######################################################'
    )
    console.info(
      '###############################################################################################################'
    )
    console.info(consoleCyan)
    console.info.apply(this, arguments) // non es6
    console.info(consoleReset) // reset
    console.info(
      '###############################################################################################################'
    )
    console.info(
      '###############################################################################################################\n\n\n' +
        consoleReset
    )
  }
  return argsArray.join(' ')
}
/**
 * wrapper for server logging
 *
 * @param {*} { level = 'info', message = String() }
 */
export const logger = async ({ level = 'info', message = String(), options = { utNow: undefined } }) => {
  // eslint-disable-line no-unused-vars
  return null
  /*
  DO NOT USE consoleX FUNCTIONS HERE!!!
  */
  // console.log(thisFile, `logger level = ${level}, message:`, message)
  // if (!clientWindow) {
  //   await connectToMongooseDb('helper.js')
  //   console.log(thisFile, 'logger logging')
  //   const { createLog } = require('../../graphql/components/log').LogResolvers.Mutation
  //   console.log(thisFile, 'logger createLog', createLog)

  //   const user = undefined // To show params expected for reference
  //   if (typeof message !== 'string') message = jsonStr(message)
  //   let result
  //   try {
  //     if (!process.env.NO_DB_LOGGING) result = await createLog(undefined, { input: { timestamp: moment(options.utNow).toISOString(), level, message } }, { user })
  //   } catch (error) {
  //     console.log(thisFile, 'logger error:', error.message)
  //   }
  //   console.log(thisFile, 'logger result:', result)
  // }
}
/**
 * Wraps normal console.log with if development.
 */
export function consoleDev() {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  if (consoleFireTest) {
    console.log(consoleYellow) // eslint-disable-line no-console
    if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
      logger({
        level: 'debug',
        message: argsArray
      })
    }
    // eslint-disable-next-line no-console
    console.log.apply(this, arguments) // non es6
    // eslint-disable-next-line no-console
    console.log(consoleReset) // reset
  }
  return argsArray.join(' ')
}

/**
 * Wraps normal console.log with if development.
 */
export function consoleInfo() {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  // if (consoleFireTest) {
  console.info(consoleBlue)
  if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
    logger({
      level: 'debug',
      message: argsArray
    })
  }
  console.info.apply(this, arguments) // non es6
  console.info(consoleReset) // reset
  // }
  return argsArray.join(' ')
}

/**
 * Replaces consoleX. Only returns string.  NO PRINTING
 */
export function consoleString() {
  const argsArray = Array.prototype.slice.call(arguments)
  return argsArray.map((arg) => JSON.stringify(arg, null, 2)).join(' ')
}

/**
 * Wraps normal console.info.
 */
export async function consoleInfoAsync() {
  const argsArray = await Array.prototype.slice.call(arguments)
  const worker = async (argsArray) => {
    console.info(consoleBlue)
    if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
      logger({
        level: 'info',
        message: argsArray
      })
    }
    await console.info.apply(this, arguments)
    await console.info(consoleReset) // reset
    return argsArray.join(' ')
  }
  return worker(argsArray)
}

/**
 * Wraps normal console.warn with if development.
 */
export function consoleWarn() {
  const argsArray = Array.prototype.slice.call(arguments)
  // console.log('helper.consoleError argsArray:', argsArray)
  const arrayStringified = []
  argsArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  console.warn(consoleMagenta) // NOT FOR BROWSER
  if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
    logger({
      level: 'warn',
      message: argsArray
    })
  }
  console.warn.apply(this, arguments)
  console.warn(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Wraps normal console.error with if development.
 */
export function consoleError() {
  const argsArray = Array.prototype.slice.call(arguments)
  if (!clientWindow) console.error(consoleRed)
  if (logger && (process.env.NODE_ENV === 'production' || FAKE_NODE_ENV_PRODUCTION) && !clientWindow) {
    logger({
      level: 'error',
      message: argsArray
    })
  }
  if (process.env.NODE_ENV === 'test') {
    console.error(consoleRed)
    console.error.apply(this, arguments)
    console.error(consoleReset)
  } else {
    console.error.apply(this, arguments)
  }
  // if (process.env.NODE_ENV === 'test' || !clientWindow) {
  // console.log(consoleRed)
  // console.log.apply(this, arguments)
  // console.log(consoleReset)
  // } else {
  //   console.error.apply(this, arguments)
  // }
  if (!clientWindow) console.error(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Wraps normal console.error with if development.
 */
export function consoleLoggerError() {
  // Should be same as consoleError but without logging.
  // Not DRY because want TOTALLY separate.
  // If in doubt, copy above function and remove logging
  const argsArray = Array.prototype.slice.call(arguments)
  if (!clientWindow) console.error(consoleRed)
  if (process.env.NODE_ENV === 'test') {
    console.error(consoleRed)
    console.error.apply(this, arguments)
    console.error(consoleReset)
  } else {
    console.error.apply(this, arguments)
  }
  // if (process.env.NODE_ENV === 'test' || !clientWindow) {
  // console.log(consoleRed)
  // console.log.apply(this, arguments)
  // console.log(consoleReset)
  // } else {
  //   console.error.apply(this, arguments)
  // }
  if (!clientWindow) console.error(consoleReset) // reset
  return argsArray.join(' ')
}

/**
 * Allow comma notation for Error
 */
export function throwError() {
  const errorArray = Array.prototype.slice.call(arguments)
  const arrayStringified = []
  errorArray.forEach((element) => arrayStringified.push(jsonStr(element)))
  throw new Error(arrayStringified.join(' '))
}

export function consoleLog(string, limit, onString) {
  // Writes in green
  const consoleColor = consoleGreen
  if (limit === undefined) limit = 0
  if (onString === undefined) onString = ''
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    if (onString) {
      const onStart = string.indexOf('=')
      if (onStart > -1) {
        console.log(consoleColor + string.substr(0, onStart + 1) + consoleReset) // eslint-disable-line no-console
        let index = string.indexOf(onString, onStart + 1)
        if (index > -1) {
          let nextIndex = string.indexOf(onString, index + 1)
          console.log(consoleColor + index + ' | ' + nextIndex + consoleReset) // eslint-disable-line no-console
          while (index !== -1) {
            // eslint-disable-next-line no-console
            console.log(
              consoleColor +
                string.substr(index, nextIndex > index ? Math.min(limit, nextIndex - index) : limit) +
                consoleReset
            )
            index = nextIndex
            if (nextIndex !== -1) nextIndex = string.indexOf(onString, nextIndex + 1)
            console.log(consoleColor + index + ' | ' + nextIndex + consoleReset) // eslint-disable-line no-console
          }
        } else {
          // eslint-disable-next-line no-console
          console.log(
            consoleColor + (limit > 0 ? string.substr(onStart + 1, limit) : string.substr(onStart + 1)) + consoleReset
          )
        }
      } else {
        console.log(consoleColor + (limit > 0 ? string.substr(0, limit) : string) + consoleReset) // eslint-disable-line no-console
      }
    } else {
      console.log(consoleColor + (limit > 0 ? string.substr(0, limit) : string) + consoleReset) // eslint-disable-line no-console
    }
  }
}
/**
 * Used to pick a random number from a pool.  Min and max numbers are included in the pool
 *
 * @export
 * @param {*} min
 * @param {*} max
 * @returns
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min // The maximum is inclusive and the minimum is inclusive
}

export function getSampleData(minCategories, maxCategories, minSubcategories, maxSubcategories) {
  let subcategorySamples = []
  const categorySamples = []

  function getDescription() {
    switch (getRandomIntInclusive(1, 3)) {
      case 1:
        return ''
      case 2:
        return 'this is a short description'
      case 3:
        return 'this is a much longer description than a short description would usually be.  I hope it will help show different layout possibilities.'
    }
  }

  function getName() {
    switch (getRandomIntInclusive(1, 5)) {
      case 1:
        return 'Name'
      case 2:
        return 'LongerName'
      case 3:
        return 'TwoWord Naming'
      case 4:
        return 'Three Word Naming'
      case 5:
        return 'Reallylongname Withmany Separate Wordsthat Cango Onandonandonandonandonandsoforth'
    }
  }

  for (let idx2 = 0; idx2 < getRandomIntInclusive(minCategories, maxCategories); idx2++) {
    subcategorySamples = []
    for (let idx1 = 0; idx1 < getRandomIntInclusive(minSubcategories, maxSubcategories); idx1++) {
      subcategorySamples.push({
        id: 'subCat' + idx1,
        name: getName(),
        description: getDescription()
      })
    }
    // debugObj(subcategorySamples, 'subcategorySamples')
    categorySamples.push({
      id: 'Cat' + idx2,
      name: getName(),
      description: getDescription(),
      subcategories: subcategorySamples
    })
  }

  // debugObj(categorySamples, 'categorySamples')
  return categorySamples
}

/**
 * Extracts html tag content out.  e.g.  <script>
 * It will use the last set if there are more than one in the content.
 *
 * @param {*} content  Any String.
 * @param {*} tag e.g. script  No gt or lt.
 * @param {*} idText  The id of the tag (required)
 * @returns
 */
export const getHtmlTagContentById = (content, tag, idText) => {
  // If no Id, no content
  const idString = `id="${idText}"`
  const indexOfId = content.indexOf(idString)
  // Get id attribute
  if (indexOfId > -1) {
    const openTagString = `<${tag}`
    const closeTagString = `</${tag}>`
    let openTagIndex = content.indexOf(openTagString)
    let nextOpenTagIndex = content.indexOf(openTagString, openTagIndex + 1)
    // Goes to the last one if there are more than one
    while (nextOpenTagIndex > -1 && nextOpenTagIndex < indexOfId) {
      openTagIndex = nextOpenTagIndex
      nextOpenTagIndex = content.indexOf(openTagString, openTagIndex + 1)
    }
    const openTagEndIndex = content.indexOf('>', openTagIndex + 1) + 1
    const closeTagIndex = content.indexOf(closeTagString, openTagEndIndex)
    if (openTagEndIndex > -1 && closeTagIndex > -1) {
      return content.substr(openTagEndIndex, closeTagIndex - openTagEndIndex)
    }
  }
}

/**
 * Either returns the json or throws an error with the original body text in it. (e.g. Server error message.)
 *
 * @param {*} text
 */
export const getJsonWithErrorHandling = (text, { throwError = true } = {}) => {
  // consoleDev(thisFile, 'getJsonWithErrorHandling text:', text)
  try {
    const parsedBody = JSON.parse(text)
    return parsedBody
    // consoleDev(thisFile, 'getJsonWithErrorHandling parsedBody:', parsedBody)
  } catch (error) {
    if (throwError) {
      throw new Error(consoleError('\ngetJsonWithErrorHandling JSON.parse error:', error.message, ' \nbodyText:', text))
    }

    return { error: error.message, text }
  }
}

/**
 * Validates the input string for special characters, 75 char limit and consecutive spaces.
 *
 * @param {string} str - The input string to be checked.
 * @returns {boolean} - Returns true if the string contains special characters,
 * contains consecutive spaces, is more than 75 chars otherwise false.
 */
export const hasSpecialCharsOrUnacceptable = (str) => {
  const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
  const consecutiveSpacesRegex = / {2,}/

  // Check if the string is empty, null, undefined or more than 75 chars
  if (str === '' || str === ' ' || str === null || str?.length > 75 || str === undefined) {
    return true
  }

  // Check if the string contains any special characters
  // Check if the string contains consecutive spaces
  if (regex.test(str) || consecutiveSpacesRegex.test(str)) {
    return true
  } else {
    return false
  }
}

/**
 * Returns sanitized file name.  Removes invalid characters.
 *
 * @param {*} fileName
 * @returns {string} sanitizedFileName
 * @throws {Error} If the input fileName or the sanitizedFileName is empty.
 */
export const sanitizeFileName = (fileName) => {
  if (!fileName) {
    consoleError('sanitizeFileName fileName is empty.')
    return ''
  }

  // eslint-disable-next-line no-control-regex
  const forbiddenCharsRegex = /[<>:"/\\|?!*=\x00-\x1F]/g
  const sanitizedFileName = fileName.replace(forbiddenCharsRegex, '')

  if (!sanitizedFileName) {
    consoleError('sanitizeFileName sanitizedFileName is empty.')
  }

  return sanitizedFileName
}

/**
 * Does not error out on invalid string.  Instead returns it.  Like Objects.
 *
 * @param {*} text
 * @returns
 */
export const jsonPar = (text) => {
  try {
    return text && JSON.parse(text)
  } catch (error) {
    return text
  }
}

/**
 *
 *
 * @param {*} text
 * @param {*} title
 * @param {*} x
 * @param {*} y
 * @returns
 */
export const debugObjWindow = (text, title, x, y) => {
  // eslint-disable-line no-unused-vars
  if (process.env.NODE_ENV === 'development') {
    try {
      const newWindow =
        clientWindow &&
        clientWindow.open('', title, 'height=500,width=400,resizable=yes,scrollbars=yes,toolbar=yes,status=yes')
      newWindow.document.open()
      if (text === '[object] [Object]') text = jsonStr(text)
      newWindow.document.write(
        '<title>' + title + '</title>' + '<html><head></head><body><pre>' + text + '</pre></body></html>'
      )
      newWindow.document.close()
      return newWindow
    } catch (error) {
      if (error.message === 'window is not defined') {
        console.error(
          'WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW ' +
            x +
            ' ' +
            title +
            ' ' +
            y +
            ' WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW'
        )
        console.error(text)
        console.error(
          'WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW ' +
            x +
            ' ' +
            title +
            ' ' +
            y +
            ' WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW'
        )
      } else {
        console.error(error.message)
      }
    }
  }
}

/**
 *
 *
 * @param {*} stream
 * @returns
 */
export const streamToString = (stream) => {
  // consoleDev(thisFile, 'streamToString stream: ', stream)
  if (!stream) throw new Error(thisFile + 'streamToString !stream')
  const chunks = []
  return new Promise((resolve, reject) => {
    stream.on('data', (chunk) => chunks.push(chunk))
    stream.on('error', () => {
      consoleError(thisFile, 'streamToString error: ')
      return reject
    })
    stream.on('end', () => resolve(Buffer.concat(chunks).toString('base64')))
  })
}

/**
 * Sorts and Object by the passed key or first in keys() array if omitted
 *
 * @param {Object} objectToSort - Object to sort
 * @param {string} [sortKey] - Key to sort by
 * @returns
 */
export const objectSort = (objectToSort, sortKey) => {
  if (!objectToSort) return objectToSort
  if (!sortKey) sortKey = Object.keys(objectToSort)[0]
  const objectToSortCopy = deepCopy(objectToSort)
  try {
    const returnObject = objectToSortCopy.sort((a, b) => {
      if (typeof a[sortKey] === 'string' && typeof b[sortKey] === 'string') {
        const lowerA = a[sortKey].toLowerCase()
        const lowerB = b[sortKey].toLowerCase()
        return lowerA > lowerB ? 1 : -1
      } else {
        return a > b ? 1 : -1
      }
    })
    return returnObject
  } catch (error) {
    consoleError(thisFile, 'objectSort error:', error.message)
    return objectToSort
  }
}

/**
 * Remove any characters that can cause errors
 *
 * @param {*} str
 * @returns
 */
export const makeFilenameSafeString = (str) => {
  // eslint-disable-next-line no-useless-escape
  const illegalRe = /[\/\?<>\\:\*\|":]/g
  // eslint-disable-next-line no-control-regex
  const controlRe = /[\x00-\x1f\x80-\x9f]/g
  const reservedRe = /^\.+$/
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
  const replacement = ''
  const sanitized = str
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
  return sanitized
}

/**
 * Converts questionText to questionSlug with shared logic
 *
 * @param {*} questionText
 * @returns {string}
 */
export const makeSlug = (text) => {
  // consoleDev(thisFile, 'questionToSlug.questionText: ', questionText)
  /*
      slugify('some string', {
        replacement: '-',    // replace spaces with replacement
        remove: null,        // regex to remove characters
        lower: true,         // result in lower case
      })

      Ty likes underscore, limit to 10 words, remove common words like 'the', 'a', 'and', and punctuation
  */
  let slug = text
  slug = slug.replace(/\bthe\b/g, ' ')
  slug = slug.replace(/\ba\b/g, ' ')
  slug = slug.replace(/\band\b/g, ' ')
  // consoleDev(thisFile, 'questionToSlug.slug: ', slug)
  slug = slugify(slug, {
    replacement: '_',
    remove: /[*+~.,()'"!?:@]/g,
    lower: false
  })
  // consoleDev(thisFile, 'questionToSlug.slug: ', slug)
  let count = 0
  let index = 0
  let end = false
  while (count < 10) {
    index = slug.indexOf('_', index + 1)
    if (index === -1) end = true
    ++count
  }
  if (!end) slug = slug.substr(0, index)
  return slug
}

/**
 * Modifies the object pointer (if not a string) and returns the string version of questionSlug
 *
 * @param {object | string} incomingParameter
 * @returns {string}
 */
export const autoSlug = (incomingParameter) => {
  let returnSlug
  if (typeof incomingParameter === 'object') {
    const keys = Object.keys(incomingParameter)
    if (!keys.includes('questionText')) throw new Error(thisFile + 'No questionText on object keys')
    if (incomingParameter.questionText === String()) throw new Error(thisFile + 'questionText empty')
    // if (!keys.indexOf('questionSlug')) throw new Error(thisFile + 'No questionSlug on object keys')
    const slug = makeSlug(incomingParameter.questionText)
    if (!incomingParameter.questionSlug) incomingParameter.questionSlug = slug
    returnSlug = slug
  } else if (typeof incomingParameter === 'string') {
    returnSlug = makeSlug(incomingParameter)
  } else {
    const errorMessage = 'incomingParameter not string or object'
    consoleError(thisFile, errorMessage)
    throw new Error(thisFile + errorMessage)
  }
  return returnSlug
}

/**
 * Returns a zero padded displayId for sorting
 *
 * @param {string} idString In the form XXX-0, XXX-00, or XXX-000
 * @param {{ separator: string, outLength: number }} options  Contains separator character default '-'
 */
export const zeroPadId = (idString, options = {}) => {
  const { separator = '-', outLength = 4 } = options || {}

  if (!(idString && typeof idString === 'string')) return

  const separatorIndex = idString.indexOf(separator)

  // Use index to get counter
  const counter = !isNaN(Number(idString.substr(separatorIndex + 1))) && Number(idString.substr(separatorIndex + 1))

  // re-write idString with padded counter string and return
  if (counter) return idString.substr(0, separatorIndex + 1).concat(String(counter).padStart(outLength, '0'))
}

// export const baseUrl = process.env.BASE_URL ? process.env.BASE_URL : 'bbTest'
export const baseUrl = process.env.BASE_URL
  ? process.env.BASE_URL
  : process.env.VERCEL_URL
    ? 'https://' + process.env.VERCEL_URL
    : 'http://localhost:3000'
export const baseUrlNoProtocol = baseUrl.substr(baseUrl.indexOf('//') + 2) // everything after first //  (http:// or https://)
export const baseContext = baseUrl.includes('pro.caseopp.com')
  ? 'pro'
  : baseUrl.includes('sta.caseopp.com')
    ? 'sta'
    : baseUrl.includes('demo.caseopp.com')
      ? 'demo'
      : 'dev'
export const locationHost =
  process.env.PORT && process.env.RDEVS_IP ? process.env.RDEVS_IP + ':' + process.env.PORT : baseUrl
export const locationProtocol = process.env.PORT && process.env.RDEVS_IP ? 'http:' : String()
export const adminPortalBaseUrl = baseUrl.includes('pro.caseopp.com')
  ? 'https://reciprocityadmin.com'
  : 'https://reciprocityadmin.com' // Putting in helper since we will eventually want to split between staging, production, and local
export const baseApiUrl = process.env.BASE_API_URL ? process.env.BASE_API_URL : baseUrl + '/api'

/**
 *  Used in UserNotifications when it as adding displayId to the message
 *
 * @param {*} testWithDisplayId
 * @returns
 */
export const subtractDisplayId = (testWithDisplayId) => {
  const sampleDisplayId = (testWithDisplayId.includes('STA-') ? ' STA-' : ' ') + 'XYZ-1234'
  return testWithDisplayId.substr(0, Math.max(0, testWithDisplayId.length - sampleDisplayId.length)) // visual sample for length
}

/**
 * Converts an object of key value pairs to url query string.
 * Guaranteed to return string starting with ? and key/value pairs or empty.
 *
 * @param {Object} parameters
 * @returns
 */
export const buildQueryString = (parameters) => {
  // if (parameters && !isEmptyObject(parameters)) {
  //   return '?' + Object.entries(parameters)
  //     .filter(([, value]) => value !== undefined)
  //     .map(([key]) => key + '=' + parameters[key]).join('&')
  // } else return String()
  if (parameters && !isEmptyObject(parameters)) {
    const returnQueryString = new URLSearchParams()
    for (const [key, value] of Object.entries(parameters)) {
      if (value && typeof value !== 'object') {
        returnQueryString.append(key, value)
      }
    }
    const returnString = returnQueryString.toString()
    if (returnString) {
      return '?' + returnString
    } else {
      return String()
    }
  } else return String()
}

/**
 * Used to add / modify any query parameters without reloading.
 *
 * @param {*} parameters
 * @returns { urlParams, urlParamsBefore } Objects of both before and after query parameters
 */
export const getSetUrlParams = (parameters) => {
  if (clientWindow) {
    const queryString = clientWindow.location.search
    // consoleDev(thisFile, `getSetUrlParams queryString:`, queryString)
    const existingURLParameters = new URLSearchParams(queryString)
    const urlParamsBefore = {}
    for (const [key, value] of existingURLParameters) urlParamsBefore[key] = value
    // consoleDev(thisFile, `getSetUrlParams urlParamsBefore:`, urlParamsBefore)
    const urlParams = Object.assign({}, urlParamsBefore, parameters)
    // consoleDev(thisFile, `getSetUrlParams urlParams:`, urlParams)
    if (!isEmptyObject(urlParams)) {
      const newQueryString = buildQueryString(urlParams)
      if (clientWindow.history.pushState) {
        const newURL = new URL(window.location.href)
        newURL.search = newQueryString
        clientWindow.history.pushState({ path: newURL.href }, '', newURL.href)
      }
    }
    return { urlParams, urlParamsBefore }
  }
  return { urlParams: {}, urlParamsBefore: {} }
}

/**
 * Will change the name of a given field using the supplied callback function.  This is expecting JSON
 * but if you give it a cursor with the _doc prop, it will use that.
 *
 * @param {*} searchObject
 * @param {*} fieldName
 * @param {*} callback
 */
const invalidLeadCharacters = ['$', '_']
let highestLevel = 0
export const changeFieldName = (searchObject, fieldName, callback, level) => {
  if (level && level > highestLevel) {
    // consoleDev(thisFile, `level (${level} && level > highestLevel(${highestLevel})`)
    highestLevel = level
  }
  if (!level) {
    // consoleDev(thisFile, `!level level:`, level)
    highestLevel = 0
    level = 0
  }
  if (!searchObject) {
    // consoleWarn(thisFile, 'changeFieldName !searchObject returning.  fieldName:', fieldName)
    return
  }
  if (!fieldName) {
    // consoleWarn(thisFile, 'changeFieldName !fieldName returning.  searchObject:', searchObject)
    return
  }
  if (typeof searchObject === 'object') {
    // consoleDev(thisFile, `L[${level}] changeFieldName IS object`)
    if (Array.isArray(searchObject)) {
      // consoleDev(thisFile, 'ARRAY')
      for (const element of searchObject) {
        changeFieldName(element, fieldName, callback, level + 1)
      }
    } else {
      // consoleDev(thisFile, 'NOT ARRAY keys:', Object.keys(searchObject))
      // let index = 0
      for (const [key, value] of Object.entries(searchObject)) {
        // consoleDev(thisFile, `[${index}] [${key}, ${jsonStr(value)}] of searchObject:`, searchObject)
        let newKey
        if (key === fieldName) {
          // consoleDev(thisFile, `i${index} key:${key} matched fieldName:${fieldName}`)
          newKey = callback(key)
          searchObject[newKey] = searchObject[key]
          delete searchObject[key]
        } // else { consoleDev(thisFile, `key:${key} DID NOT match fieldName:${fieldName}`) }
        // consoleDev(thisFile, `key:${key} value:`, value)
        // Mongoose can add circular props.  Check for first character exceptions
        if (invalidLeadCharacters.indexOf(key.substr(0, 1)) > -1) {
          // exceptions _doc is for apollo query cursors and $or is for query syntax
          if (key !== '_doc' && key !== '$or') {
            // consoleDev(thisFile, 'invalid character skip ', key)
            continue // let _doc through since it is from Apollo / Mongo / Mongoose and wasn't called with toJSON
          }
        }
        // Go one more level (use newKey if we changed it)
        if (hasOwnProperty.call(searchObject, newKey || key)) {
          // consoleDev(thisFile, `newKey(${newKey}) || key(${key}):`, newKey || key, ' value:', value)
          if (value) {
            // consoleDev(thisFile, `calling changeFieldName(${value}, ${fieldName}, ${callback}, ${level + 1})`)
            changeFieldName(value, fieldName, callback, level + 1)
          }
        } // else consoleDev(thisFile, 'skip ', key, ' because value:', searchObject[key])
        // ++index
      }
    }
  }
}

/**
 * Converts date to midnight for the correct date regardless of timezone
 * @param {Date} date - date to convert
 * @returns Date
 */
export const getUTCDate = (date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

/**
 * Converts date to the current UTC date and time
 * @param {Date} date - date to convert
 * @returns Date
 */
export const getUTCDateTime = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  )
}

/**
 * Converts date to midnight (to the millisecond) and 0 offset
 *
 * @param {*} fromDateTimeZoneISOString
 * @returns
 */
export const dobDate = (fromDateTimeZoneISOString) => {
  const dobDate = new Date(fromDateTimeZoneISOString)
  // set the hours ahead of utc by 12 to account for our time difference
  dobDate.setHours(12, 0, 0, 0)
  return moment(dobDate).utc(true)
}

/**
 * creates a 24hour range minus 1 millisecond
 * start at 00:00:00.000 and the end at 23:59:59.999
 * @param {*} date
 * @returns {Object} {start,end}
 */
export const dobDateRange = (date = new Date()) => {
  const dobDateStart = moment(date).startOf('day').utc(true)
  const dobDateEnd = moment(date).endOf('day').utc(true)
  return { start: dobDateStart, end: dobDateEnd }
}

/**
 * creates a 1 year range minus 1 millisecond
 * start at 00:00:00.000 and the end at 23:59:59.999
 * @param {String} age
 * @returns {Object} {start,end}
 */
export const getDobRangeFromAge = (age) => {
  age = parseInt(age)
  const start = moment().subtract(age, 'years').subtract(364, 'days').startOf('day').utc(true)
  const end = moment().subtract(age, 'years').endOf('day').utc(true)
  return { start, end }
}

/**
 * converts all keys of an object to lower case (i.e. for more tolerance of variation in API POST JSON bodies)
 * @param {Object} object
 */
export const lowerCaseAllKeys = (object) => {
  const keys = Object.keys(object)
  let n = keys.length
  const lowerCasedKeysObject = {}
  while (n--) {
    const key = keys[n]
    lowerCasedKeysObject[key.toLowerCase()] = object[key]
  }
  return lowerCasedKeysObject
}

/**
 * trims all keys (two levels deep, so including key.key1, key.key2) of an object of their whitespace (both ends)
 * @param {Object} object
 */
export const trimAllKeys = (object) => {
  const keys = Object.keys(object)
  let n = keys.length
  const trimmedKeysObject = {}
  while (n--) {
    const key = keys[n]
    const trimmedKey = key.trim()
    if (_.isObject(object[key]) && !_.isArray(object[key]) && !_.isEmpty(object[key])) {
      // this key's value is an object with keys that we also want to trim
      const secondTierKeys = Object.keys(object[key])
      let o = secondTierKeys.length
      trimmedKeysObject[trimmedKey] = {}
      while (o--) {
        const secondTierKey = secondTierKeys[o]
        const trimmedSecondTierKey = secondTierKey.trim()
        trimmedKeysObject[trimmedKey][trimmedSecondTierKey] = object[key][secondTierKey]
      }
    } else {
      // key's value is not an object
      trimmedKeysObject[trimmedKey] = object[key]
    }
  }
  return trimmedKeysObject
}

/**
 * turns an object into a human-readable sentence string, like "Client entered 'foo' for the 'bar' field, 'taco' for the 'bell' field."
 * will return null if the object is empty
 * @param {Object} object
 * @param {String} who
 */
export const objectToSentenceString = (object, subject, verb) => {
  const keys = Object.keys(object)
  const len = Object.keys(object).length
  if (!len) return null
  if (len) {
    let sentenceString = subject + ` ${verb} `
    if (len === 1) return (sentenceString += `'${object[0]}' for the '${keys[0]}' field.`)
    keys.forEach((key, index) => {
      if (index === 0) {
        sentenceString += `'${object[key]}' for the '${key}' field`
      }
      if (index > 0 && index < keys.length - 1) {
        sentenceString += `, '${object[key]}' for the '${key}' field`
      }
      if (index === keys.length - 1) {
        sentenceString += `, and '${object[key]}' for the '${key}' field.`
      }
    })
    return sentenceString
  }
}

/**
 * Used to shortcut &&'ing long variable chains.  Will return undefined if any element doesn't exist.
 *
 * @param {*} startRef
 * @param {*} chainString
 * @returns
 */
export const chainVar = (startRef, chainString) => {
  if (!startRef) return undefined
  if (!chainString) return undefined
  if (typeof chainString !== 'string') return undefined

  const propArray = chainString.split('.')
  let reference = startRef
  for (const prop of propArray) {
    if (!reference[prop]) return undefined
    reference = reference[prop]
  }

  return reference
}
/**
 * Replaces illegal characters with allowed equivalent
 *
 * @param {*} nameToBuild
 * @returns
 */
export const classNameBuilder = (nameToBuild) => {
  // consoleDev(thisFile, 'classNameBuilder nameToBuild:', nameToBuild)
  nameToBuild = nameToBuild.replace(/@/, '-AT-')
  nameToBuild = nameToBuild.replace(/\./, '-DOT-')
  // consoleDev(thisFile, 'classNameBuilder RETURNING nameToBuild:', nameToBuild)
  return nameToBuild
}
/**
 * Capitalized the first letter of a string regardless of the rest.
 *
 * @param {*} s
 * @returns
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 * NOTE: This does not work consistently, should use lodash's isEqual instead
 */
export function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    // consoleDev(thisFile, 'shallowEqual !length')
    return false
  }

  // Test for A's keys different from B.
  const bHasOwnProperty = hasOwnProperty.bind(objB)
  for (let i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      // consoleDev(thisFile, 'shallowEqual objA[keysA[i]] !== objB[keysA[i] objA[keysA[i]:', objA[keysA[i]],
      //   ' \nobjB[keysA[i]]:', objB[keysA[i]])
      // consoleDev(thisFile, 'shallowEqual !bHasOwnProperty')
      return false
    }
  }

  return true
}

/**
 *  Only compares top level
 *
 * @export
 * @param {*} instance
 * @param {*} nextProps
 * @param {*} nextState
 * @returns
 */
export function shallowCompare(instance, nextProps, nextState) {
  return !shallowEqual(instance.props, nextProps) || !shallowEqual(instance.state, nextState)
}

/**
 * determines if an opp is a test opp base on whether it is scrambled,
 * the email contains @rdevs.com, or the email contains @caseopp.com
 * @param {*} opp
 * @returns {Boolean} true if any of the above are true, false otherwise
 */
export const isTestOpp = (opp) => {
  // consoleInfo(thisFile, 'isTestOpp opp.email:', opp.email)
  if (opp?.scrambled) return true
  if (opp?.email?.includes('@rdevs.com')) return true
  if (opp?.email?.includes('@caseopp.com')) return true
  return false
}

/**
 * takes a camelCase and returns Camel Case
 * thanks https://stackoverflow.com/questions/4149276/how-to-convert-camelcase-to-camel-case
 * @param {String} camelCaseString
 */
export const deCamelCase = (camelCaseString) => {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1') // insert a space before all caps
    .replace(/^./, (str) => str.toUpperCase()) // uppercase the first character
}

/**
 * Deletes a specific key from an object or array of objects.
 * @param {*} obj - The object or array to scrub.
 * @param {string} keyName - The key to delete.
 * @returns The scrubbed object or array.
 */
const deleteKey = (obj, keyName) => {
  if (!obj || typeof obj !== 'object') return obj
  if (Array.isArray(obj)) {
    return obj.map((item) => deleteKey(item, keyName))
  }
  const newObj = { ...obj }
  delete newObj[keyName]
  Object.keys(newObj).forEach((key) => {
    newObj[key] = deleteKey(newObj[key], keyName)
  })
  return newObj
}

/**
 * Deletes multiple keys from an object or array of objects.
 * @param {Object|Array} obj - The object or array to scrub.
 * @param {Array} keyNames - The keys to delete.
 * @returns The scrubbed object or array.
 */
export const deleteKeys = (obj, keyNames) => {
  keyNames.forEach((keyName) => {
    obj = deleteKey(obj, keyName)
  })
  return obj
}

/**
 * Accepts any type of input and scrubs __typename from objects and arrays of objects.
 * @param {*} input - The input to scrub.
 * @returns The scrubbed input.
 */
export const scrubTypename = (input) => {
  if (!input) return undefined // called without input, return undefined
  const clonedInput = cloneDeep(input) // clone whatever we got
  // Recursive function to scrub __typename
  const scrub = (data) => {
    if (Array.isArray(data)) {
      return data.map((item) => scrub(item))
    } else if (typeof data === 'object' && data !== null) {
      if (isEmpty(data)) return data // return the object even if empty
      const cleanedData = { ...data }
      delete cleanedData.__typename
      Object.keys(cleanedData).forEach((key) => {
        cleanedData[key] = scrub(cleanedData[key]) // recursively scrub nested objects
      })
      return cleanedData
    }
    return data // return data if it's not an array or object
  }
  return scrub(clonedInput)
}

/**
 * moment-less formatting from raw timestamp or string of raw timestamp, to string
 * @param {Date or String} timestamp
 * @returns
 */
export const timestampToLocaleString = (timestamp, dateOnly) => {
  // eslint-disable-line no-unused-vars
  if (typeof timestamp === 'number') {
    // as with a raw timestamp like 1951-03-24T00:00:00.000+00:00
    // DB - timestamp- 2021-05-05T17:13:31.464+00:00
    // 1648480576663 - current timestamp from cl of timestamp
    return new Date(timestamp).toLocaleString('en-US')
  } else if (typeof timestamp !== 'string') {
    timestamp = String(timestamp)
  }

  return new Date(timestamp).toLocaleString('en-US')
}

/**
 * Returns the difference between 2 objects. Handy for comparing why lodash isEqual is returning false
 * @param obj1
 * @param obj2
 *
 * @returns diff - difference between obj1 and obj2
 */
export const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))
  return diff
}

/**
 * Returns current time given a timezone and state
 * @param {String} timeZone - Timezone as a String
 * @param {String} oppState - Needed to check if daylight savings is in effect
 * @param {Boolean} asDateString - Boolean to return full date string
 * @param {DateTime} selectedDateTime - DateTime to change to current timezone
 * @returns {String} time - current time in location
 */
export const getCurrentTime = (timeZone, oppState, asDateString = false, selectedDateTime = new Date()) => {
  try {
    // DST === daylight savings time
    let isDST = false
    const stateIgnoresDST = ignoreDST.includes(oppState) // get states that do not observe DST
    /* Checking if the timezone is observing DST. */
    if (!stateIgnoresDST || !oppState) {
      const janDate = new Date(2000, 0, 1) // months are zero based, year is arbitrary, use jan since DST is march - nov
      const janTimeZoneOffset = janDate.getTimezoneOffset() // difference in minutes between UTC and local
      const curDate = selectedDateTime
      const curDateTimeZoneOffset = curDate.getTimezoneOffset() // difference in minutes between UTC and local
      // if the timezone offset is the same as january, then it is not observing DST
      isDST = janTimeZoneOffset !== curDateTimeZoneOffset // detect DST observation
    }
    const currentTimeZone = timeZones.find((tZone) => tZone.name === timeZone || tZone.std === timeZone || '')
    if (!currentTimeZone) return
    const timeZoneString = isDST ? currentTimeZone.dst : currentTimeZone.std
    let currentTime = selectedDateTime.toLocaleString('en-US', { timeZone: timeZoneString })
    if (asDateString) {
      return currentTime
    }
    currentTime = selectedDateTime.toLocaleTimeString('en-US', { timeZone: timeZoneString, timeStyle: 'short' })
    return currentTime
  } catch (error) {
    consoleError(thisFile, 'timezone error: ', error.message)
    return ''
  }
}

/**
 * Calculates the appropriate text color (either black or white) based on the given background color.
 * To adjust the sensitivity use getLuminance
 *
 * @param {string} bgColor The background color in hexadecimal format.
 * @returns {string} The recommended text color ("#ffffff" for white or "#000000" for black).
 */

export const getTextColor = (bgColor) => {
  if (!bgColor) return '#ffffff'
  const getRGB = (c) => parseInt(c, 16) || c

  const getsRGB = (c) =>
    getRGB(c) / 255 <= 0.03928 ? getRGB(c) / 255 / 12.92 : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)

  const getLuminance = (hexColor) =>
    0.0126 * getsRGB(hexColor.substr(1, 2)) + // Red
    0.7152 * getsRGB(hexColor.substr(3, 2)) + // Green
    0.0722 * getsRGB(hexColor.substr(-2)) // Blue

  const getContrast = (f, b) => {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
  }

  const whiteContrast = getContrast(bgColor, '#ffffff')
  const blackContrast = getContrast(bgColor, '#000000')

  return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}

/**
 * Gets the timezone based on the area code
 * @param {String} phoneToCheck
 * @returns {Object} timezone
 */
export const getTimezoneFromPhone = async (phoneToCheck) => {
  const results = {}
  const areaCode = phoneToCheck.substring(0, 3)
  const selectedTimeZone = phoneAreaCodes.find((timeZone) => timeZone?.[areaCode])?.[areaCode]
  results.timeZone =
    timeZones.find((tz) => {
      let timezone = tz.std
      if (timezone === 'America/Anchorage') timezone = 'AKST'
      return !!(timezone === selectedTimeZone)
    })?.name || ''
  return results
}

/** Stolen from https://stackoverflow.com/questions/23013573/swap-key-with-value-json
 *
 * @param {*} obj
 * @returns
 */
export const objectReverse = (obj) => {
  return Object.entries(obj).reduce((ret, entry) => {
    const [key, value] = entry
    ret[value] = key
    return ret
  }, {})
}

/**
 * looks for the two-letter abbreviation for a state name
 * @param {String} stateName
 * @returns {String} the state's two-letter abbreviation, or the original string
 */
export const stateNameToAbbreviation = (stateName) => {
  const foundState = states.find((state) => state.name === stateName)
  if (foundState) {
    return foundState.abbreviation
  } else {
    return stateName
  }
}

/**
 * Posts the given payload to the specified endpoint
 * @param {String} endpoint
 * @param {Object} payload
 * @returns
 */
export const postToInternalApi = async (endpoint, payload) => {
  try {
    const postEndpoint = new URL(endpoint, baseUrl)
    await fetch(postEndpoint.toString(), {
      method: 'POST',
      body: JSON.stringify(payload)
    })
  } catch (error) {
    consoleError(thisFile, 'postToInternalAPI error: ', error.message)
  }
}

/**
 * converts a boolean to Yes or No
 * @param {Boolean} bool
 * @returns {String} Yes or No
 */
export const boolToYN = (bool) => {
  if (bool && bool !== 'false') {
    return 'Y'
  } else {
    return 'N'
  }
}

/**
 * Returns Version
 *
 * @returns {string}
 */
export const getVersion = () => {
  if (variantToBool(process.env.STATIC_VERSION)) {
    return 'YYYY.MM.DD'
  }
  return appVersion
}

/**
 * Adds Review Flag to RI Admin Portal
 * @param {object} reviewFlag review flag object must include attributes: opportunityId, oppDisplayId, reason, description, agent, createdBy
 */
export const addReviewFlag = async (reviewFlag) => {
  try {
    const reviewFlagsURL = new URL('api/reviewFlags/addReviewFlag', adminPortalBaseUrl)
    // eslint-disable-next-line no-undef
    await fetch(reviewFlagsURL.toString(), {
      method: 'POST',
      headers: { apitoken: process.env.RI_ADMIN_PORTAL_AUTH_TOKEN },
      body: JSON.stringify(reviewFlag)
    })
  } catch (error) {
    throw new Error('addReviewFlagError error: ' + error.message)
  }
}

/**
 * Finds if arrays share any common elements
 * @param {array} array1
 * @param {array} array2
 * @returns {boolean} true if there are common elements
 */
export const arraysShareCommonElements = (array1, array2) => {
  if (!array1 || !array2) {
    return false
  }
  return array1.some((element) => array2.includes(element))
}

/**
 * Checks if one date is after another date
 * @param {string} date1
 * @param {string} date2
 * @returns {boolean} true if date1 is after date2
 */
export const isDateAfter = (date1, date2) => {
  const date1Date = new Date(date1)
  const date2Date = new Date(date2)
  return date1Date > date2Date
}

/**
 * Cleans unwanted edge case encoding conversions to match the file paths in google drive
 * @param {string} link
 * @returns {string} cleanedLink
 */
export const cleanEdgeCaseDocLinks = (linkToClean) => {
  const cleanedLink = decodeURIComponent(
    linkToClean.split(process.env.GOOGLE_CLOUD_STORAGE_DOCUMENTS_BUCKET)?.pop()?.replace('/o/', '')
  )
  return cleanedLink
}

/**
 * Checks if date is older than amount of time
 * @param {number} amountOfUnits number of days, weeks, months, or years
 * @param {string} unit 'days', 'weeks', 'months', or 'years'
 * @param {string} date date to check
 * @returns {boolean} true if date is older than amountOfUnits
 */
export const isDateOlderThan = (amountOfUnits, unit, date) => {
  const dateDate = new Date(date)
  const currentDate = new Date()
  const timeDiff = Math.abs(currentDate.getTime() - dateDate.getTime())
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  switch (unit) {
    case 'days':
      return diffDays > amountOfUnits
    case 'weeks':
      return diffDays > amountOfUnits * 7
    case 'months':
      return diffDays > amountOfUnits * 30
    case 'years':
      return diffDays > amountOfUnits * 365
    default:
      consoleError(thisFile, 'isDateOlderThan error: ', 'unit not found')
      return false
  }
}

/**
 * convert queryFilters to mongoDB filters
 * @param {String} operator
 * @param {Any} value
 * @returns filter value
 */
export const getMongoFilter = (operator, value) => {
  let filterValue = {}
  switch (operator) {
    case 'isAnyOf':
      filterValue = { $in: value }
      break
    case 'isNotAnyOf':
      filterValue = { $nin: value }
      break
    case 'equals': // strict equality
    case '=':
      filterValue = { $eq: value }
      break
    case 'not':
    case '!=':
      filterValue = { $ne: value }
      break
    case 'isEmpty':
      filterValue = { $eq: '' }
      break
    case 'isNotEmpty':
      filterValue = { $nin: [null, ''] }
      break
    case 'after':
    case '>':
      filterValue = { $gt: value }
      break
    case 'onOrAfter':
    case '>=':
      filterValue = { $gte: value }
      break
    case 'before':
    case '<':
      filterValue = { $lt: value }
      break
    case 'onOrBefore':
    case '<=':
      filterValue = { $lte: value }
      break
    case 'is':
    case 'timeAgo':
    default:
      filterValue = value
      break
  }
  return filterValue
}

/**
 * parses values based on how they are stored in the database
 * @param {String} field
 * @param {Any} value
 * @param {String} operator optional
 * @returns formatted value
 */
export const mongoValueFormatter = (field, value, operator = null) => {
  let formattedValue = value
  switch (field) {
    case '_id':
    case 'campaignId':
    case 'flagIds':
    case 'case.flagIds':
      if (Array.isArray(value)) {
        formattedValue = value.map((singleValue) => new ObjectId(singleValue))
        break
      }
      formattedValue = new ObjectId(value)
      break
    case 'createdAt':
    case 'updatedAt':
    case 'timestamp':
    case 'lastContacted':
    case 'lastAgentUpdated':
    case 'lastAgentContacted':
    case 'dateSentClient':
    case 'dateSentFirm':
    case 'clientPortalLastAccessed':
    case 'case.questionnaireCompletedDate':
    case 'deadline':
    case 'dateSigned':
    case 'retainerSignedDate':
    case 'claimFormSignedDate':
    case 'case.caseStatusUpdated':
    case 'otherEversignSignedDate':
    case 'claimForSentDate':
    case 'case.claimFiledDate':
      if (operator === 'timeAgo') {
        formattedValue = { $gte: new Date(value.after), $lte: new Date(value.before) }
        break
      }
      if (value === null) {
        formattedValue = { $exists: false }
      } else {
        formattedValue = new Date(value)
      }
      break
    case 'contactAttemptsCount':
    case 'priority':
    case 'followUp':
      formattedValue = parseInt(value, 10)
      break
    case 'phone':
    case 'alternatePhone':
      formattedValue = formatPhoneNumber(value)
      break
    case 'injuredPartyDifferent':
    case 'addressManuallyVerified':
    case 'doNotCallAltPhone':
    case 'doNotCallPhone':
    case 'doNotEmailPrimary':
    case 'doNotEmailSecondary':
    case 'doNotMail':
    case 'doNotTextPhone':
    case 'doNotTextAltPhone':
    case 'isCase':
    case 'negotiatedRetainerApproval':
    case 'smartyStreetsVerified':
    case 'userConsent':
      formattedValue = variantToBool(formattedValue)
      break
    case 'comments':
    case 'clientComments':
    case 'caseComments':
      formattedValue = value
      break
    default:
      if (operator === 'timeAgo' && value?.after && value?.before) {
        formattedValue = { $gte: new Date(value.after), $lte: new Date(value.before) }
        break
      }
      break
  }
  return formattedValue
}

/**
 * Returns an object with a qualified property that is true if the opportunity is qualified, and a
 * missingQualifications property that is an array of objects with a section property and a missing
 * property that is an array of strings.
 * @param {Object} opportunity - the opportunity object
 * @param {Object} campaign - the campaign object
 * @param {Boolean} qualifyingQuestionsMet - true if all qualifying questions are met, false if not
 * @param {Object} user - the user object
 * @param {Boolean} requiredQuestionsAnswered - true if all required questions are answered, false if not
 * @param {Boolean} conditionalQuestionsAnswered - true if all visible conditional questions are answered, false if not
 * @param {Array} unqualifiedQuestions - array of questions that are unqualified
 * @param {Boolean} answerRestrictionsMet - true if any answer restrictions are met, false if not
 * @returns {{ qualified: boolean, missingQualifications: Array }} An object with two properties: qualified and missingQualifications.
 */
export const oppIsQualified = (
  opportunity,
  campaign,
  qualifyingQuestionsMet,
  requiredQuestionsAnswered,
  user,
  conditionalQuestionsAnswered,
  unqualifiedQuestions = [],
  answerRestrictionsMet = false
) => {
  const tempMissingQualifications = []
  let visibleConditionalsAnswered = true
  if (!conditionalQuestionsAnswered) {
    tempMissingQualifications.push({
      section: 'Unanswered Conditionals',
      missing: ['a conditional question needs answer']
    })
    visibleConditionalsAnswered = false
  }
  // clientInfoQualified is true when firstName, lastName, dob, and either email or password are populated
  let clientInfoQualified = true
  const missingClientInfo = { section: 'Client Info', missing: [] }
  if (!opportunity?.firstName) missingClientInfo.missing.push('First Name')
  if (!opportunity?.lastName) missingClientInfo.missing.push('Last Name')
  if (!opportunity?.email && !opportunity?.phone) missingClientInfo.missing.push('Email or Phone')
  if (!opportunity?.dob) missingClientInfo.missing.push('DOB')
  // if missingClientInfo.missing.length > 0, add it to tempMissingQualifications and set clientInfoQualified to false
  if (missingClientInfo.missing.length) {
    tempMissingQualifications.push(missingClientInfo)
    clientInfoQualified = false
  }
  // addressQualified is true when addressStreet, addressCity, addressState, and addressZip are populated
  let addressQualified = true
  const missingAddress = { section: 'Address', missing: [] }
  if (!opportunity?.addressStreet) missingAddress.missing.push('Street')
  if (!opportunity?.addressCity) missingAddress.missing.push('City')
  if (!opportunity?.addressState) missingAddress.missing.push('State')
  if (!opportunity?.addressZIP) missingAddress.missing.push('Zip')
  // if missingAddress.missing.length > 0, add it to tempMissingQualifications and set addressQualified to false
  if (missingAddress.missing.length) {
    tempMissingQualifications.push(missingAddress)
    addressQualified = false
  }
  // campaignNotInactive is true when campaign.status is not 'Inactive'
  let campaignNotInactive = true
  if (campaign?.status === 'inactive') {
    tempMissingQualifications.push({ section: 'Campaign Inactive', missing: [] })
    campaignNotInactive = false
  }
  // injuredPartyInfo will be true if opportunity.injuredPartyDifferent is not true,
  // or if opportunity.injuredPartyDifferent is true, and opportunity.injuredPartyFirstName,
  // opportunity.injuredPartyLastName, opportunity.injuredPartyDOB, and opportunity.injuredPartyRelation are populated
  let injuredPartyInfo = true
  if (opportunity?.injuredPartyDifferent) {
    const missingInjuredPartyInfo = { section: 'Injured Party Info', missing: [] }
    if (!opportunity?.injuredPartyFirstName) missingInjuredPartyInfo.missing.push('First Name')
    if (!opportunity?.injuredPartyLastName) missingInjuredPartyInfo.missing.push('Last Name')
    if (!opportunity?.injuredPartyDOB) missingInjuredPartyInfo.missing.push('DOB')
    if (!opportunity?.injuredPartyRelation) missingInjuredPartyInfo.missing.push('Relation')
    if (missingInjuredPartyInfo.missing.length) {
      tempMissingQualifications.push(missingInjuredPartyInfo)
      injuredPartyInfo = false
    }
  }
  // smartyStreetsVerified is true when opportunity.smartyStreetsVerified or opportunity.addressManuallyVerified is true
  let smartyStreetsVerified = true
  if (!opportunity?.smartyStreetsVerified && !opportunity?.addressManuallyVerified) {
    tempMissingQualifications.push({ section: 'Address not verified' })
    smartyStreetsVerified = false
  }
  if (!qualifyingQuestionsMet) {
    const missingQualifyingQuestions = { section: 'Qualifying Questions' }
    if (unqualifiedQuestions.length) {
      missingQualifyingQuestions.missing = unqualifiedQuestions?.map((question) => question.questionText)
    }
    tempMissingQualifications.push(missingQualifyingQuestions)
  }
  if (!requiredQuestionsAnswered) {
    tempMissingQualifications.push({ section: 'Required Questions not completed' })
  }
  if (answerRestrictionsMet) {
    tempMissingQualifications.push({ section: 'An Answer is outside of the answer restrictions' })
  }

  if (
    clientInfoQualified &&
    visibleConditionalsAnswered &&
    addressQualified &&
    campaignNotInactive &&
    injuredPartyInfo &&
    smartyStreetsVerified &&
    qualifyingQuestionsMet &&
    requiredQuestionsAnswered &&
    !answerRestrictionsMet
  ) {
    // opportunityIsQualified is true when all of the above are true
    return {
      qualified: true,
      missingQualifications: []
    }
  } else if (
    hasAccess('action:sendDocsOnInactive', user) &&
    clientInfoQualified &&
    visibleConditionalsAnswered &&
    addressQualified &&
    injuredPartyInfo &&
    smartyStreetsVerified &&
    qualifyingQuestionsMet &&
    requiredQuestionsAnswered &&
    !answerRestrictionsMet
  ) {
    // opportunityIsQualified is true when user has access to send docs on inactive campaigns and all of the above are true except for campaignNotInactive
    return {
      qualified: true,
      missingQualifications: []
    }
  }
  return {
    qualified: false,
    missingQualifications: tempMissingQualifications
  }
}

/**
 * Return a new object with all the keys of the original object except for the ones in the exclude
 * array.
 * @param obj - The object to filter
 * @param exclude - an array of keys to exclude from the object
 * @returns A new object with the keys that are not in the exclude array.
 */
export function filterObject(obj, exclude) {
  return Object.keys(obj)
    .filter((key) => !exclude.includes(key))
    .reduce((newObj, key) => {
      newObj[key] = obj[key]
      return newObj
    }, {})
}

/**
 * It takes a campaignId and a delta object, and then it makes a request to the server to check if any
 * event actions should be triggered
 * @param {String} opportunityId - the opportunity id
 * @param {String} campaignId - the campaign id
 * @param {Object} delta - the delta object
 */
export const checkEventActions = async (opportunityId, campaignId, delta = { status: 'test' }) => {
  const eventActionsResponse = await fetch(
    `/api/rest/eventActionCheck?opportunityId=${opportunityId}&campaignId=${campaignId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        delta
      })
    }
  )
  const eventActions = await eventActionsResponse.json()
  if (eventActions.triggered) {
    return eventActions.eventActions
  }
  return false
}
/**
 * Takes an interval and a range, and returns a date that is the interval ago from the current date
 * @param interval - The number of minutes, hours, days, weeks, months, or years to go back.
 * @param range - The range of time you want to get.
 * @param default - The default date to return if the interval is 0.
 * @returns A date object
 */
export const getTimeAgo = (interval, range, defaultDate = 'beginning') => {
  if (interval === 0) {
    switch (defaultDate) {
      case 'now':
        return new Date()
      case 'beginning':
      default:
        return new Date(0) // Beginning of time
    }
  }
  const timeAgo = new Date()
  switch (range) {
    case 'Minutes':
      return timeAgo.setMinutes(timeAgo.getMinutes() - interval)
    case 'Hours':
      return timeAgo.setHours(timeAgo.getHours() - interval)
    case 'Days':
      return timeAgo.setDate(timeAgo.getDate() - interval)
    case 'Weeks':
      return timeAgo.setDate(timeAgo.getDate() - interval * 7)
    case 'Months':
      return timeAgo.setMonth(timeAgo.getMonth() - interval)
    case 'Years':
      return timeAgo.setFullYear(timeAgo.getFullYear() - interval)
    default:
      return timeAgo
  }
}

const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const getHash = customAlphabet(characters, 5)

const allowedDomains = [
  'https://pro.caseopp.com',
  'https://sta.caseopp.com',
  'http://localhost:3000',
  'https://reciprocityadmin.com'
]

/**
 * It takes a URL, checks if it's allowed, checks if it's already in the database, and if not, creates
 * a new shortened URL and returns it
 * @param db - The database connection
 * @param url - The URL to shorten
 * @returns The shortened url
 */
export const urlShortener = async (db, url) => {
  if (!url) return 'badRequest'
  const urls = db.collection('shortenedUrls')

  const allowed = allowedDomains.some((domain) => url.startsWith(domain))
  if (!allowed) return 'badRequest'

  const existingLink = await urls.findOne({ url })
  if (existingLink) return existingLink.shortenedUrl

  const hash = getHash()
  const shortenedUrl = `${baseUrl}/s/${hash}`

  const result = await urls.insertOne({ url, shortenedUrl, hash })
  if (!result) return 'internalServerError'

  return shortenedUrl
}

/**
 * The function escapes special characters in a string by replacing them with their escaped
 * equivalents.
 * @param {string} value - The input value that needs to be escaped for special characters.
 * @returns {string} The escaped string.
 */
export const escapeSpecialChars = (value) => {
  return value
    .replace(/\n/g, '\\n')
    .replace(/"/g, '\\"')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t')
    .replace(/\f/g, '\\f')
}

/**
 * Takes a JSON object and a header string and returns a formData object if the header is multipart/form-data
 * otherwise it returns the JSON object
 * @param {String} body - The body of the request
 * @param {String} header - The header of the request
 * @returns {JSON Object || FormData Object} - The body of the request
 */
export const formDataParseBodyIfRequested = (body, header) => {
  if (header.includes('multipart/form-data')) {
    try {
      const bodyAsObject = JSON.parse(body)
      const formData = new FormData()
      for (const field in bodyAsObject) {
        // skip loop if the value is a object
        if (field !== 'file' && typeof bodyAsObject[field] === 'object') continue

        // add the data to the formData
        if (field === 'file') {
          const bufferFile = Buffer.from(bodyAsObject[field])
          formData.append(field, bufferFile, 'retainer.pdf')
        } else {
          formData.append(field, bodyAsObject[field])
        }
      }
      return formData
    } catch (error) {
      // if error, return the body as is
      return body
    }
  } else {
    return body
  }
}

/**
 * Takes a newFilterModel and flags and returns the flags that should be shown based on the filter model.
 * @param {import('@mui/x-data-grid-premium').GridFilterModel} newFilterModel - The filter model object
 * @param {Array<import('@src/dictionaries/commonInterfaces').Flag>} flags - The flags array
 * @param {Array<import('@src/dictionaries/commonInterfaces').Campaign>} campaigns - An array of campaigns
 * @returns {Array} - The flags that should be shown based on the filter model
 */
export const handleFlagOptionsChange = (newFilterModel, flags, campaigns = []) => {
  const itemsOfInterest = newFilterModel.items.filter(
    (item) =>
      item?.value && (item?.field === 'flagIds' || item?.field === 'campaignGroups' || item?.field === 'campaignId')
  )
  // If the filter model contains a filter for flagIds, campaignGroups, or campaignId, then set the flags to be shown to appropriate values.
  if (itemsOfInterest?.length > 0) {
    // Use the campaignId filter to filter the flags, include flags that have no campaigns or campaignGroups
    // and flags that have the same campaignGroups as the campaignId filter
    if (itemsOfInterest.some((item) => item?.field === 'campaignId' && item?.operator === 'is')) {
      const campaignIdIsFilter = itemsOfInterest.filter((item) => item?.field === 'campaignId')
      const campaignInfo = campaigns?.find((campaign) => campaign?._id === campaignIdIsFilter[0]?.value)
      const filteredFlags = flags?.filter(
        (flag) =>
          flag?.campaigns?.includes(campaignIdIsFilter[0]?.value) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0) ||
          flag?.campaignGroups?.some((group) => campaignInfo?.groups?.includes(group))
      )
      return filteredFlags
    } else if (itemsOfInterest.some((item) => item?.field === 'campaignId' && item?.operator === 'isAnyOf')) {
      const campaignIdIsAnyOfFilter = itemsOfInterest.filter((item) => item?.field === 'campaignId')
      const campaignInfoArray = campaignIdIsAnyOfFilter[0]?.value?.map((campaignId) =>
        campaigns?.find((campaign) => campaign?._id === campaignId)
      )
      const filteredFlags = flags?.filter(
        (flag) =>
          flag?.campaigns?.some((campaign) => campaignIdIsAnyOfFilter[0]?.value?.includes(campaign)) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0) ||
          flag?.campaignGroups?.some((group) =>
            campaignInfoArray?.some((campaignInfo) => campaignInfo?.groups?.includes(group))
          )
      )
      return filteredFlags
    } else if (itemsOfInterest.some((item) => item?.field === 'campaignGroups' && item?.operator === 'is')) {
      const campaignGroupsIsFilter = itemsOfInterest.filter((item) => item?.field === 'campaignGroups')
      const filteredFlags = flags?.filter(
        (flag) =>
          flag?.campaignGroups?.includes(campaignGroupsIsFilter[0]?.value) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0)
      )
      return filteredFlags
    } else if (itemsOfInterest.some((item) => item?.field === 'campaignGroups' && item?.operator === 'isAnyOf')) {
      const campaignGroupsIsAnyOfFilter = itemsOfInterest.filter((item) => item?.field === 'campaignGroups')
      const filteredFlags = flags?.filter(
        (flag) =>
          flag?.campaignGroups?.some((group) => campaignGroupsIsAnyOfFilter[0]?.value?.includes(group)) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0)
      )
      return filteredFlags
    } else if (itemsOfInterest.some((item) => item?.field === 'campaignGroups' && item?.operator === 'isNotAnyOf')) {
      const campaignGroupsIsNotAnyOfFilter = itemsOfInterest.filter((item) => item?.field === 'campaignGroups')
      const filteredFlags = flags?.filter(
        (flag) =>
          !flag?.campaignGroups?.some((group) => campaignGroupsIsNotAnyOfFilter[0]?.value?.includes(group)) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0)
      )
      return filteredFlags
    } else if (itemsOfInterest.some((item) => item?.field === 'campaignGroups' && item?.operator === 'not')) {
      const campaignGroupsNotFilter = itemsOfInterest.filter((item) => item?.field === 'campaignGroups')
      const filteredFlags = flags?.filter(
        (flag) =>
          !flag?.campaignGroups?.includes(campaignGroupsNotFilter[0]?.value) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0)
      )
      return filteredFlags
    } else if (itemsOfInterest.some((item) => item?.field === 'campaignId' && item?.operator === 'isNotAnyOf')) {
      const campaignIdIsNotAnyOfFilter = itemsOfInterest.filter((item) => item?.field === 'campaignId')
      const filteredFlags = flags?.filter(
        (flag) =>
          !flag?.campaigns?.some((campaign) => campaignIdIsNotAnyOfFilter[0]?.value?.includes(campaign)) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0)
      )
      return filteredFlags
    } else if (itemsOfInterest.some((item) => item?.field === 'campaignId' && item?.operator === 'not')) {
      const campaignIdNotFilter = itemsOfInterest.filter((item) => item?.field === 'campaignId')
      const filteredFlags = flags?.filter(
        (flag) =>
          !flag?.campaigns?.includes(campaignIdNotFilter[0]?.value) ||
          (flag?.campaigns?.length === 0 && flag?.campaignGroups?.length === 0)
      )
      return filteredFlags
    } else {
      return flags
    }
  } else {
    // Set the available flags to be shown to all of the flags
    return flags
  }
}

/**
 * Merges default columns for given user groups.
 *
 * @param {Array} allUserGroups - Array of all user groups.
 * @param {Array} userGroups - Array of user groups to merge default columns for.
 * @returns {Array} - Array of merged default columns.
 */
export const handleMergingDefaultColumnsForUserGroups = (allUserGroups, userGroups) => {
  let unionArray = []
  if (!userGroups?.length) return unionArray
  userGroups.forEach((group) => {
    const findGroupInUserGroups = allUserGroups?.find((userGroup) => userGroup?.name === group)
    if (findGroupInUserGroups) {
      const set1 = new Set(unionArray)
      const set2 = new Set(findGroupInUserGroups.defaultColumns)
      const unionSet = new Set([...set1, ...set2])
      unionArray = [...unionSet]
    }
  })
  return unionArray
}

/**
 * Takes the qualified question from the destination campaign and the answers from the origin opp and returns the number of verified answers
 * @param {Array} destinationCampaignQualifiedQuestions from destination campaign
 * @param {Object} answersFromOriginOpp from origin opp
 * @returns {number} number of verified answers
 */
export const getNumberOfVerifiedAnswersFromNewCampaign = (
  destinationCampaignQualifiedQuestions,
  answersFromOriginOpp
) => {
  // Filled out answers for origin opp
  let count = 0
  const originOppFilledOutAnswers = Object.keys(answersFromOriginOpp)
    .filter((answer) => answersFromOriginOpp[answer]?.answer && answersFromOriginOpp[answer]?.answer?.trim() !== '')
    .map((key) => ({ question: key, answer: answersFromOriginOpp[key]?.answer }))
  for (const question of destinationCampaignQualifiedQuestions) {
    const matchingAnswerSlug = originOppFilledOutAnswers.find((answer) => answer.question === question.questionSlug)
    if (matchingAnswerSlug) {
      if (question?.qualifiedOptions?.includes(matchingAnswerSlug.answer)) {
        count++
      } else if (question?.answerType === 'Select Many') {
        const matchingAnswerSlugs = matchingAnswerSlug.answer.split(' | ')
        if (matchingAnswerSlugs.every((answerSlug) => question?.qualifiedOptions?.includes(answerSlug))) {
          count++
        }
      } else if (question?.answerType === 'Select One') {
        if (variantToBool(matchingAnswerSlug.answer) === question.qualifier) {
          count++
        }
        // Count any text fields so long as they are answered
      } else if (question?.answerType === 'Text Field' && answersFromOriginOpp[question?.questionSlug]?.answer) {
        count++
      }
    }
  }
  return count
}

/**
 * Checks if a string is invalid JSON
 * @param {String} json
 * @returns {Boolean} true if the string is invalid JSON
 */
export const isInvalidJSON = (json) => {
  try {
    JSON.parse(json)
    return false
  } catch (error) {
    return true
  }
}
